body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a.link {
  color: black;
  text-decoration: none;
}
a.link:hover {
  color: #555;
}


